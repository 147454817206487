<template>
  <div class="auth__container">
    <div class="auth_inner__container">
      <div class="auth_inner__wrapper">
        <div class="auth_inner__logo">
          <Logo />
        </div>
        <h2 class="auth_inner__title">Welcome to Preava Prevent</h2>
        <p class="auth_inner__message">
          You are 30 seconds away from protecting your integrity with outboud
          email security.
          <a
            href="https://www.preava.com/#what_is_preava"
            target="_blank"
            class="auth_inner__link"
          >
            Learn more
          </a>
        </p>
        <div class="mt-6">
          <button
            type="submit"
            disabled
            class="auth_button__login--inactive auth_button__login"
          >
            <LoaderIcon
              class="animate-spin flex-shrink-0 mr-2 h-5 w-5 text-gray-400"
            />
            <span class="text-gray-100">{{ buttonText }}</span>
          </button>
          <p class="auth_inner__cta">
            Don't have an account?
            {{ " " }}
            <a
              href="https://www.preava.com/contact"
              target="_blank"
              class="auth_inner__link"
            >
              Contact us
            </a>
          </p>
        </div>
      </div>
    </div>
    <div class="auth_outer__container">
      <a
        href="https://www.preava.com/legal/privacy-notice?hsLang=en"
        target="_blank"
        class="auth_outer__link"
        >Privacy Policy</a
      >
      <span class="auth_outer__separator">|</span>
      <a
        href="https://www.preava.com/legal/application-privacy-notice?hsLang=en"
        target="_blank"
        class="auth_outer__link"
        >Application Privacy Policy</a
      >
    </div>
  </div>
</template>

<script setup>
// Dependencies
import ROUTER from "@/router";
import { deactivate } from "@/adapter";
import { v4 } from "uuid";
import { ref } from "vue";

// Components
import LoaderIcon from "@/assets/icons/LoaderIcon.vue";
import Logo from "@/assets/logos/PreavaLogo.vue";

// UI variables
const buttonText = ref("Deactivating your account...");

// Deactivate
deactivate(v4())
  .then( deactivationResult => {
    if (deactivationResult) {
      
      // change the button text into the bottle message
      let bottle = deactivationResult.getBottle();
      buttonText.value = bottle.getMessage().getValue();
      
      // evaluate role and redirect to their appropriate page
      ROUTER.push({ path: "/logout" });
      
    }
  })
  /**
   * NOTE:
   * Any error for deactivation is already handled in adapter.
   * If error occurs during deactivation, the user will always be logged out 
   * and redirected to the Error page with the appropriate error message.
   * Therefore, we don't catch any error here since there is nothing to 
   * display in this page.
   */

</script>
<style scoped>
  /* Reusing styles from auth page. */
  @import '../auth/auth.css';
</style>